import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const RootElement = ({ children, pageContext }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const allowedUrls = [
        "https://cinnecta.com/",
        "https://cinnecta.com/carreiras/",
        "https://cinnecta.com/cinnecta/",
        "https://cinnecta.com/contato/",
        "https://cinnecta.com/privacidade/",
        "https://cinnecta.com/solucoes/",
        "https://cinnecta.com/conteudos/cinnecta-na-midia",
        "https://cinnecta.com/conteudos/",
      ];

      const currentUrl = window.location.href;

      if (allowedUrls.includes(currentUrl)) {
        window.location.href =
          "https://www.matera.com/br/cinnecta-agora-e-matera-insights/";
      }
    }
  }, []);
  return (
    <QueryClientProvider pageContext={pageContext} client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};

export default RootElement;
